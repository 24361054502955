import GameHistoryButton from "../buttons/GameHistoryButton";
import { GameHistoryList } from "../modals/GameHistoryDialog";
import BaseBottomSheet from "./BaseBottomSheet";
import { IconShuffle } from "../icons/IconShuffle";
import {
  getDateKeyForLevel,
  gridLevelNumber,
} from "../../lib/utils/gridLevels";
import { formatDate } from "../../lib/utils/locales";
import { dateKeyToDate } from "../../lib/utils/dateFunctions";
import { useMemo, useState } from "react";
import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";

export default function RecentGamesSheet({ grid }) {
  return (
    <BaseBottomSheet
      content={<RecentGamesSheetContent />}
      trigger={<GameHistoryButton grid={grid} />}
      scrollable={false}
    />
  );
}

function RecentGamesSheetContent() {
  const [getLocalGridHistory] = usePersistentStore((state) => [
    state.getLocalGridHistory,
  ]);

  // Get today's date
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  const yesterdayDateKey = getDateKeyForLevel(gridLevelNumber - 1);
  const randomLevel = Math.floor(Math.random() * gridLevelNumber) + 1;
  const randomDateKey = getDateKeyForLevel(randomLevel);

  function toggleShow() {
    setShowPlayed(!showPlayed);
  }

  const allGrids = getLocalGridHistory();
  const unplayedGrids = allGrids
    ? allGrids.filter((item) => {
        return !item.attempt || item.attempt.guessesLeft > 0;
      })
    : [];

  const [showPlayed, setShowPlayed] = useState(unplayedGrids == 0);

  //  const showGrids = showPlayed ? allGrids : unplayedGrids;
  const showGrids = allGrids;

  return (
    <>
      {/* <div className="stickdy top-0 bg-white px-4 pb-4 font-medium">
        Quick Play
      </div>
      <div className="mb-6 flex items-center justify-around space-x-4 px-4">
        <QuickPlayButton
          title="Today"
          icon={
            <span className="whitespace-nowrap text-md">
              {formatDate(today, "MMM do")}
            </span>
          }
          href={"/"}
        />
        <QuickPlayButton
          title="Yesterday"
          icon={
            <span className="text-ellipsis whitespace-nowrap text-md">
              {formatDate(yesterday, "MMM do")}
            </span>
          }
          href={`/${yesterdayDateKey}`}
        />
        <QuickPlayButton
          title="Random"
          icon={<IconShuffle className="h-6 w-6" />}
          href={`/${randomDateKey}`}
        />
      </div> */}
      <div className="mt-2 flex items-center justify-between bg-white px-4 pb-3 font-semibold">
        Previous 7 Days
      </div>
      <GameHistoryList showGrids={showGrids} />
    </>
  );
}

function QuickPlayButton({ title, icon, href }) {
  return (
    <a
      href={href}
      className="h-18 flex w-full max-w-sm flex-col items-center justify-center rounded-lg border-2 border-gray-200 bg-white p-3 text-gray-700  hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
    >
      <h5 className="mb-1 text-xs font-semibold uppercase tracking-tight text-gray-700 dark:text-white">
        {title}
      </h5>
      <p className="text-center">{icon}</p>
    </a>
  );
}
