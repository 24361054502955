import React, { useState } from "react";
import { useSWRConfig } from "swr";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputField } from "./fields/InputField";
import { CheckboxField } from "./fields/CheckboxField";
import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";

export function CaptureForm() {
  const [setIsOpen, user] = useGameStore((state) => [
    state.setIsRegisterOpen,
    // state.setIsCaptureComplete,
    state.user,
  ]);

  const [setIsComplete] = usePersistentStore((state) => [state.setPromoNMFC]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Formik
      //validateOnMount={true}
      initialValues={{
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        acceptTerms: false,
      }}
      validationSchema={Yup.object({
        email: Yup.string().required("Please enter your email address"),
        firstName: Yup.string().required("Please enter your first name"),
        lastName: Yup.string().required("Please enter your last name"),
        acceptTerms: Yup.boolean().oneOf(
          [true],
          "You must agree to enter the draw."
        ),
      })}
      onSubmit={async (values, { setErrors }) => {
        setIsSubmitting(true);
        console.log("payload", values);
        const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/promo/submit`;

        fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        })
          .then((res) => {
            setIsSubmitting(false);

            return res.json();
          })
          .then((data) => {
            if (data.errors) {
              setErrors(data.errors);
            } else {
              setIsComplete(true);
            }
          });
      }}
    >
      {(formik) => (
        <Form className="space-y-5" action="#" method="POST">
          <div className="space-y-3">
            <InputField
              label="Email"
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              placeholder="name@email.com"
            />
            <div className="">
              <div className="block pl-px text-xs font-medium text-gray-900 dark:text-white">
                <span className="label-text">Name</span>
              </div>
              <div className="flex space-x-2">
                <InputField
                  id="firstName"
                  name="firstName"
                  type="text"
                  autoComplete="given-name"
                  placeholder="First name"
                />
                <InputField
                  id="lastName"
                  name="lastName"
                  type="text"
                  autoComplete="family-name"
                  placeholder="Last name"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pl-0.5">
            <CheckboxField
              label={
                <>
                  I agree to the{" "}
                  <a
                    className="font-medium text-blue-600 hover:underline"
                    href="/img/nmfc/240229_NMFC_Kangaroos_Gridley_Day_T&Cs.pdf"
                    target="_blank"
                  >
                    terms &amp; conditions
                  </a>{" "}
                  and{" "}
                  <a
                    className="font-medium text-blue-600 hover:underline"
                    href="https://www.nmfc.com.au/privacy"
                    target="_blank"
                  >
                    privacy policy
                  </a>
                  .
                </>
              }
              id="acceptTerms"
              name="acceptTerms"
            />
          </div>
          <button
            type="submit"
            className={`w-full rounded-lg bg-blue-600 px-5 py-2.5 text-center text-md font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:opacity-50 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
              isSubmitting && "loading"
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Enter the Draw!"}
          </button>
        </Form>
      )}
    </Formik>
  );
}
