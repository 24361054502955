import { BaseDialog } from "./BaseDialog";
import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";

import { CaptureForm } from "../forms/CaptureForm";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { IconCheckLg } from "../icons/IconCheckLg";
import TweetButton from "../buttons/TweetButton";
import ShareButton from "../buttons/ShareButton";

function CaptureDialog(props) {
  const [isOpen, setIsOpen] = useGameStore((state) => [
    state.isCaptureOpen,
    state.setIsCaptureOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <BaseDialog
      isOpen={isOpen}
      closeModal={closeModal}
      size="md"
      modalTitle={
        <div className="">
          <h1 className="pl-1 pt-1 text-left text-xl font-semibold leading-tight tracking-tight text-gray-900 dark:text-white sm:pl-2 md:text-2xl">
            Enter for your chance to win!
          </h1>
        </div>
      }
      modalBody={<ModalBody />}
    />
  );
}

function ModalBody() {
  const [isComplete] = usePersistentStore((state) => [state.promoNMFC]);

  // const [isComplete] = useGameStore((state) => [state.isCaptureComplete]);

  return (
    <>
      <div className="p-1 text-sm text-gray-900 sm:pl-2 sm:text-md">
        One lucky winner will take home a{" "}
        <span className="font-bold">signed Nick Larkey guernsey</span> and
        heritage merchandise pack.
      </div>

      <div className="relative mt-2 flex min-h-[17rem] items-center justify-center space-y-3 p-1 sm:min-h-[16rem] sm:p-2">
        <Transition className="w-full" show={!isComplete}>
          <Fade delay="delay-[0ms]">
            <CaptureForm />
          </Fade>
        </Transition>
        <Transition className="absolute" show={isComplete}>
          <Fade delay="delay-[0ms]">
            <div className="space-y-4 px-2 md:space-y-6">
              <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-blue-600 text-white">
                <IconCheckLg className="h-10 w-10" />
              </div>
              <p className="px-4 text-center text-sm text-gray-900 dark:text-white sm:text-md">
                You're in! Stay tuned to your inbox to find out if you are the
                lucky winner.
              </p>
              <div className="mt-8 flex items-center justify-center">
                <TweetButton />
                <ShareButton />
              </div>
            </div>
          </Fade>
        </Transition>
      </div>
    </>
  );
}

const Fade = ({ delay, children }) => (
  <Transition.Child
    enter={`transition-all ease-in-out duration-700 ${delay}`}
    enterFrom="opacity-0 translate-y-6"
    enterTo="opacity-100 translate-y-0"
    leave="transition-all ease-in-out duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition.Child>
);

export default CaptureDialog;
