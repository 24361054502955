import { gridLevelDateKey } from "../../lib/utils/gridLevels";
import TabGroup from "../buttons/TabGroup";
import CaptureDialog from "../modals/CaptureDialog";
import PromoDialog from "../modals/PromoDialog";
import TabFrames from "../TabFrames";
import BasePage from "./BasePage";

export function PageIndex() {
  return (
    <BasePage>
      <main className="mx-auto min-h-[34.625rem] min-w-min bg-slate-100 text-center sm:min-h-[41.375rem] md:min-h-[52.5625rem]">
        {/* <Hero /> */}
        <div className="mx-auto">
          <TabGroup />
          <TabFrames />
        </div>
        {/* {initError && <NetworkError onRetry={init} />} */}
        {gridLevelDateKey === "2024-03-04" && (
          <>
            <PromoDialog />
            <CaptureDialog />
          </>
        )}
      </main>
    </BasePage>
  );
}
